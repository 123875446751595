import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import "./blog.scss";
import SEO from "../components/seo";
import PageWrapper from "../PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogGridItem from "../components/BlogGridItem";
import { useFormik } from 'formik';

// TODO: Move this to Helpers & re-use in contact.js
function encode( data ){
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

function Hero() {

  return (

    <>
      <div className="bg_cover d-lg-flex align-items-center" style={{ height: "200px" }}>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="header-hero-content">
                <h1 className="hero-title"><b>BLOG</b></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="header-hero-image d-none d-lg-flex align-items-center">
          <div className="image">
            <img src={ heroImg } alt="Hero" />
          </div>
        </div>  */}

      </div>
    </>

  );

}

const BlogIndex = ( props ) => {

  const { data } = props;
  const { numberOfPages, pageNumber, previousPagePath, nextPagePath } = props.pageContext; // props.pageContext.humanPageNumber
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  const isFirst = pageNumber === 0;
  const isLast = pageNumber === numberOfPages - 1;

  const [ response, setResponse ] = useState({
    show: false,
    class: "",
    message: ""
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      fullname: "",
      email: ""
    },
    onSubmit: (values, { resetForm })=>{

      console.log( "Submitting message..." );

      fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "blog-form", ...values })
        })
          .then(( response ) => {

              if ( response.ok && response.status === 200 ){

                  resetForm();
                  setResponse({ show: true, class: "success", message: "Thank you! Your message has been received." });

              } else {

                  throw new Error("Form submission failed");

              }

          })
          .catch(error => {

              console.log( "Error:", error );
              setResponse({ 
                  show: true,
                  class: "danger",
                  message: "Something went wrong. Please try again." 
              });

      });

    },
    validate: values => {

      let errors = {}

      if ( !values.fullname ){
          errors.fullname = "Required";
      }
      if ( !values.email ){
          errors.email = "Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      }

      return errors;

    }
  });

  const disabledStyle = {
    opacity: 0.3,
    backgroundColor: "gray !important"
  }

  return (

    <PageWrapper>
      <Header component={ <Hero /> } title={ siteTitle } />
      <SEO title="lexiQA Blog - Automated Quality Assurance" />
      <SEO description="All the latest news and updates from lexiQA. Subscribe to our blog to get email notifications." />

      <h2 className="hero-title text-center">Blog</h2>      
      <section className="blog-area pt-115 pb-120 bg-white">

        <div className="container">

          {/*}
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="section-title text-center pb-20">
                <h6 className="sub-title">Our Blog</h6>
                <h4 className="title">
                  Latest <span>News.</span>
                </h4>
              </div>
            </div>
          </div>
        */}

          <div className="row">
            { posts.map( ( { node } ) => {
              const title = node.frontmatter.title || node.fields.slug
              return <BlogGridItem key={ node.fields.slug } node={ node } title={ title } />;
            } ) }
          </div>


          {/* NEXT / PAGINATION / PREVIOUS */ }
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-btn mt-60 text-center">

                { !isFirst && (
                  <Link className="btn btn-primary mx-2" to={ previousPagePath } rel="prev">Prev</Link>
                ) }

                <div className="btn btn-primary">{ pageNumber + 1 } / { numberOfPages - 1 }</div>                

                {/* Array.from( { length: numberOfPages }, ( _, i ) => {

                  let className = "btn mx-2 d-none d-sm-inline-block ";
                  className += ( pageNumber === i ) ? "btn-secondary" : "btn-primary";

                  return (
                    <Link className={ className } key={ `pagination-number${i + 1}` } to={ `/blog/${i === 0 ? "" : i + 1}` }>
                      { i + 1 }
                    </Link>
                  );
                } )
              */}

                { !isLast && (
                  <Link className="btn btn-primary mx-2" to={ nextPagePath } rel="next">Next</Link>
                ) }

              </div>
            </div>
          </div>

          {/* GET IN BACK FORM */ }
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-12 mt-5">
              <h3 className="text-center">Get updated for every new article</h3>  

              <form 
                onSubmit={formik.handleSubmit}
                id="blog-form"
                name="blog-form"
                data-netlify="true"
                netlify-honeypot="email_address"
              >
                <input type="hidden" name="form-name" value="blog-form" />
                <div className="row">
                  <div className="col-xs-12 col-md-6">

                    <div className="hidden" hidden>
                      <label>Don&apos;t fill this out if you&apos;re human: <input name="email_address" /></label>
                    </div>

                    <div className="contact-form mt-45">
                      <input 
                        onChange={formik.handleChange}
                        value={formik.values.fullname}
                        type="text"
                        name="fullname"
                        placeholder="Full Name" 
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="contact-form mt-45">
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        type="email"
                        name="email"
                        placeholder="Email" 
                      />
                    </div>
                  </div>

                  <p className="form-message"></p>
                  <div className="col-md-12">
                    <div className="contact-form mt-45 d-flex flex-column flex-md-row justify-content-center text-max-width">

                    <button
                      disabled={ formik.isValid ? false : true } 
                      style={ formik.isValid ? {} : disabledStyle } 
                      type="submit"
                      className="lexiqa-bg-green text-white main-btn btn-primary">Subscribe</button>

                      <small className="ml-0 ml-md-4 mt-4 mt-md-0">By clicking the Subscribe button, you agree that you have read and you acknowledged our <Link className="contact--privacy-link lxq-link" to="/privacy">Privacy policy</Link>. Your personal information will not be sold, distributed or leased to third parties without your explicit consent.</small>
                    </div>
                  </div>
                </div>
              </form>
              { response.show && 
                <div className="email-response">
                      <button className={ "btn py-3 pl-5 btn-" + response.class } onClick={()=> setResponse({ ...response, show: false }) }>
                          <span className="mr-4">{ response.message } </span>
                          <span className="ml-4 pr-2 email-response--close">&times;</span>
                      </button>
                  </div>
              }
            </div>
          </div>

        </div>

      </section>

      <Footer />

    </PageWrapper>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query ( $skip: Int! ) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blog/"} }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 9
      skip: $skip
      ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            author
            title
            thumbnail
          }
        }
      }
    }
  }
`
